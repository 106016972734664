<template>
  <div>
    <div class="input-box">
      <el-input
        v-show="key"
        v-model="row.remark"
        size="small"
        class="id"
        :autofocus="key"
        @blur="handleInputBlur(row)"
      />
    </div>
    <span
      v-show="!key"
      @click="$store.state.btn_if.indexOf(val.pw) != -1 && showinput(row)"
    >
      {{ row.remark }}</span
    >
    <i
      v-show="!key"
      :class="$store.state.btn_if.indexOf(val.pw) != -1 && 'el-icon-edit'"
      @click="$store.state.btn_if.indexOf(val.pw) != -1 && showinput(row)"
    />
  </div>
</template>

<script>
export default {
  props: ['row', 'val'],
  data() {
    return {
      key: false
    }
  },
  methods: {
    async handleInputBlur(row) {
      if (this.val.userId) {
        var { data: res } = await this.$http.post(this.val.url, {
          ...row,
          id: row.userId
        })
      } else {
        var { data: res } = await this.$http.post(this.val.url, row)
      }

      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.$emit('getlist')
        this.key = false
      } else {
        this.$message.error(res.message)
      }
    },
    showinput() {
      this.key = true
      setTimeout(() => {
        $('.id input').focus()
      }, 10)
    }
  }
}
</script>

<style lang="less" scoped></style>
