<template>
  <div>
    <el-dialog
      title="审核不通过原因"
      :visible.sync="show"
      width="30%"
      style="text-align: center;"
      @close="$emit('update:dialogVisible', false)"
    >
      <el-input type="textarea" :rows="7" placeholder="" v-model="form.remark">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      show: this.dialogVisible
    }
  },
  watch: {
    dialogVisible() {
      this.show = this.dialogVisible
    }
  },
  methods: {
    clear() {
      this.$emit('update:dialogVisible', false)
    },
    submit() {
      this.$emit('update:dialogVisible', false)
      this.$http
        .post('/admin/User/cert', {
          ...this.form,
          certStatus: 3
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.$emit('getlist')
          } else {
            this.$message.error(res.message)
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.el-dialog__header {
  text-align: center;
}
</style>
